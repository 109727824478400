const JobOpenNewsCardButtonh = () => {
  return (
    <a
      className="review--job--btn--news--card--h"
      style={{ display: "inline-block" }}
      href="#"
    >
      Review Job Opening
    </a>
  );
};

export default JobOpenNewsCardButtonh;
