import React from "react";


import Recommends  from '../img/Recommend-1.png'



const Cardah= ()=>{


return(



    <>
        
        <div class="Recommend-card-ah">
                            <div class="Recommend-card-img-ah">
                                <img src={Recommends} alt=""/>
                            </div>
                            <div class="Recommend-card-tital-ah">
                                <h2>20 Things Jurnalism Studenta Should Know Before Working in Local News Station</h2>
                            </div>
                        </div>

    </>
    
)




}



export default Cardah
