const InsightSecTwoh = () => {
  const data = [
    {
      text: "Users",
      value: "2345",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 5.9304C-0.103849 5.515 -0.103849 4.84151 0.311548 4.42612L4.27264 0.465021C4.68804 0.0496237 5.36153 0.0496236 5.77693 0.465021L9.73802 4.42612C10.1534 4.84151 10.1534 5.515 9.73802 5.9304C9.32263 6.3458 8.64913 6.3458 8.23374 5.9304L5.02479 2.72145L1.81583 5.9304C1.40044 6.3458 0.726945 6.3458 0.311548 5.9304Z"
            fill="#89C400"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 12.4292C-0.103849 12.0139 -0.103849 11.3404 0.311548 10.925L4.27264 6.96387C4.68804 6.54847 5.36153 6.54847 5.77693 6.96387L9.73802 10.925C10.1534 11.3404 10.1534 12.0138 9.73802 12.4292C9.32263 12.8446 8.64913 12.8446 8.23374 12.4292L5.02479 9.22029L1.81583 12.4292C1.40044 12.8446 0.726945 12.8446 0.311548 12.4292Z"
            fill="#89C400"
          />
        </svg>
      ),
    },

    {
      text: "New Users",
      value: "56",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 5.9304C-0.103849 5.515 -0.103849 4.84151 0.311548 4.42612L4.27264 0.465021C4.68804 0.0496237 5.36153 0.0496236 5.77693 0.465021L9.73802 4.42612C10.1534 4.84151 10.1534 5.515 9.73802 5.9304C9.32263 6.3458 8.64913 6.3458 8.23374 5.9304L5.02479 2.72145L1.81583 5.9304C1.40044 6.3458 0.726945 6.3458 0.311548 5.9304Z"
            fill="#89C400"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 12.4292C-0.103849 12.0139 -0.103849 11.3404 0.311548 10.925L4.27264 6.96387C4.68804 6.54847 5.36153 6.54847 5.77693 6.96387L9.73802 10.925C10.1534 11.3404 10.1534 12.0138 9.73802 12.4292C9.32263 12.8446 8.64913 12.8446 8.23374 12.4292L5.02479 9.22029L1.81583 12.4292C1.40044 12.8446 0.726945 12.8446 0.311548 12.4292Z"
            fill="#89C400"
          />
        </svg>
      ),
    },

    {
      text: "Sessions",
      value: "1554",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 5.9304C-0.103849 5.515 -0.103849 4.84151 0.311548 4.42612L4.27264 0.465021C4.68804 0.0496237 5.36153 0.0496236 5.77693 0.465021L9.73802 4.42612C10.1534 4.84151 10.1534 5.515 9.73802 5.9304C9.32263 6.3458 8.64913 6.3458 8.23374 5.9304L5.02479 2.72145L1.81583 5.9304C1.40044 6.3458 0.726945 6.3458 0.311548 5.9304Z"
            fill="#89C400"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 12.4292C-0.103849 12.0139 -0.103849 11.3404 0.311548 10.925L4.27264 6.96387C4.68804 6.54847 5.36153 6.54847 5.77693 6.96387L9.73802 10.925C10.1534 11.3404 10.1534 12.0138 9.73802 12.4292C9.32263 12.8446 8.64913 12.8446 8.23374 12.4292L5.02479 9.22029L1.81583 12.4292C1.40044 12.8446 0.726945 12.8446 0.311548 12.4292Z"
            fill="#89C400"
          />
        </svg>
      ),
    },

    {
      text: "Number of Session per User",
      value: "1.18",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 7.11737C-0.103849 7.53277 -0.103849 8.20626 0.311548 8.62165L4.27264 12.5827C4.68804 12.9981 5.36153 12.9981 5.77693 12.5828L9.73802 8.62166C10.1534 8.20626 10.1534 7.53277 9.73802 7.11737C9.32263 6.70197 8.64913 6.70197 8.23374 7.11737L5.02479 10.3263L1.81583 7.11737C1.40044 6.70197 0.726945 6.70197 0.311548 7.11737Z"
            fill="#EB5757"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 0.618524C-0.103849 1.03392 -0.103849 1.70741 0.311548 2.12281L4.27264 6.0839C4.68804 6.4993 5.36153 6.4993 5.77693 6.0839L9.73802 2.12281C10.1534 1.70741 10.1534 1.03392 9.73802 0.618525C9.32263 0.203128 8.64913 0.203128 8.23374 0.618525L5.02479 3.82748L1.81583 0.618524C1.40044 0.203127 0.726945 0.203127 0.311548 0.618524Z"
            fill="#EB5757"
          />
        </svg>
      ),
    },
    {
      text: "Avg. Session Duration",
      value: "00:03:13",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 7.11737C-0.103849 7.53277 -0.103849 8.20626 0.311548 8.62165L4.27264 12.5827C4.68804 12.9981 5.36153 12.9981 5.77693 12.5828L9.73802 8.62166C10.1534 8.20626 10.1534 7.53277 9.73802 7.11737C9.32263 6.70197 8.64913 6.70197 8.23374 7.11737L5.02479 10.3263L1.81583 7.11737C1.40044 6.70197 0.726945 6.70197 0.311548 7.11737Z"
            fill="#EB5757"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 0.618524C-0.103849 1.03392 -0.103849 1.70741 0.311548 2.12281L4.27264 6.0839C4.68804 6.4993 5.36153 6.4993 5.77693 6.0839L9.73802 2.12281C10.1534 1.70741 10.1534 1.03392 9.73802 0.618525C9.32263 0.203128 8.64913 0.203128 8.23374 0.618525L5.02479 3.82748L1.81583 0.618524C1.40044 0.203127 0.726945 0.203127 0.311548 0.618524Z"
            fill="#EB5757"
          />
        </svg>
      ),
    },
    {
      text: "Bounce Rate",
      value: "47.32%",
      percent: "6%",
      arrowsvg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 7.11737C-0.103849 7.53277 -0.103849 8.20626 0.311548 8.62165L4.27264 12.5827C4.68804 12.9981 5.36153 12.9981 5.77693 12.5828L9.73802 8.62166C10.1534 8.20626 10.1534 7.53277 9.73802 7.11737C9.32263 6.70197 8.64913 6.70197 8.23374 7.11737L5.02479 10.3263L1.81583 7.11737C1.40044 6.70197 0.726945 6.70197 0.311548 7.11737Z"
            fill="#EB5757"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.311548 0.618524C-0.103849 1.03392 -0.103849 1.70741 0.311548 2.12281L4.27264 6.0839C4.68804 6.4993 5.36153 6.4993 5.77693 6.0839L9.73802 2.12281C10.1534 1.70741 10.1534 1.03392 9.73802 0.618525C9.32263 0.203128 8.64913 0.203128 8.23374 0.618525L5.02479 3.82748L1.81583 0.618524C1.40044 0.203127 0.726945 0.203127 0.311548 0.618524Z"
            fill="#EB5757"
          />
        </svg>
      ),
    },
  ];

  return (
    <>
      <div className="dash--sec--one--card--cont--h">
        {data.map((item, index) => (
          <div id={index} className="dash--sec--one--card--h">
            <p>{item.text}</p>
            <div className="insight--sec--two--stat--bottom">
              <h1>{item.value}</h1>
              <div className="insight--sec--two--stat--bottom--right--h">
                <div className="insight--sec--two--stat--bottom--right--svg">
                  {item.arrowsvg}
                </div>
                <p>{item.percent}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default InsightSecTwoh;
