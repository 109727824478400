import React from 'react'

const PagesDashboardTable = () => {

  const rows = Array.from({ length: 10 });


  return (
    <div className="dashpages_data_box">
      <table>
        <thead>
          <tr>
            <th><input type="checkbox" name="" id="" /></th>
            <th> <select name="" id="">
              <option value="">Title</option>
              <option value="">Title</option>
              <option value="">Title</option>
            </select></th>
            <th> <select name="" id="">
              <option value="">Author</option>
              <option value="">Author</option>
              <option value="">Author</option>
            </select></th>
            <th> <select name="" id="">
              <option value="">Status</option>
              <option value="">Status</option>
              <option value="">Status</option>
            </select></th>
            <th> <select name="" id="">
              <option value="">Date</option>
              <option value="">Date</option>
              <option value="">Date</option>
            </select></th>
            <th>
              <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0.75C1.34315 0.75 0 2.09315 0 3.75V14.25C0 15.9069 1.34315 17.25 3 17.25H9C10.6569 17.25 12 15.9069 12 14.25V3.75C12 2.09315 10.6569 0.75 9 0.75H3ZM7.5 4.5C7.5 5.32843 6.82843 6 6 6C5.17157 6 4.5 5.32843 4.5 4.5C4.5 3.67157 5.17157 3 6 3C6.82843 3 7.5 3.67157 7.5 4.5ZM7.5 9C7.5 9.82843 6.82843 10.5 6 10.5C5.17157 10.5 4.5 9.82843 4.5 9C4.5 8.17157 5.17157 7.5 6 7.5C6.82843 7.5 7.5 8.17157 7.5 9ZM6 15C6.82843 15 7.5 14.3284 7.5 13.5C7.5 12.6716 6.82843 12 6 12C5.17157 12 4.5 12.6716 4.5 13.5C4.5 14.3284 5.17157 15 6 15Z" fill="#828282" />
                </svg>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                  <path d="M0 6.5336C0.210068 6.22446 0.394518 5.89436 0.62508 5.60618C1.88036 4.04475 3.35084 2.74007 5.12873 1.81788C6.34303 1.18912 7.6188 0.790901 8.97656 0.659908C11.6101 0.403163 13.9977 1.121 16.1804 2.60907C17.5996 3.57842 18.778 4.79403 19.8079 6.17206C20.064 6.51264 20.064 6.87942 19.8079 7.22C18.6192 8.81287 17.2307 10.1804 15.5348 11.1969C14.1309 12.0405 12.6297 12.575 11.0004 12.7322C8.63328 12.9627 6.4455 12.3968 4.41143 11.1655C2.73088 10.149 1.35263 8.78143 0.179326 7.19904C0.102472 7.09425 0.0563597 6.9685 0 6.85322C0 6.74843 0 6.64364 0 6.5336ZM9.86294 2.39949C7.58294 2.4414 5.75893 4.42725 5.7948 6.82179C5.83066 9.16393 7.77764 11.0345 10.1294 10.9873C12.4043 10.9454 14.2385 8.9491 14.1975 6.56504C14.1617 4.2229 12.2147 2.35757 9.86294 2.39949ZM7.73665 6.70651C7.73665 7.96404 8.75624 9.00674 9.98591 9.00674C11.2514 9.00674 12.271 7.95356 12.2659 6.64888C12.2608 5.40707 11.2361 4.37485 10.0064 4.38009C8.76137 4.38009 7.73665 5.42803 7.73665 6.70651Z" fill="#828282" />
                </svg></li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                  <path d="M17.0511 8.97426C17.0146 12.8684 14.1671 16.2461 10.3506 16.8814C8.45836 17.1968 6.68039 16.8814 5.00297 15.9536C4.93442 15.917 4.82929 15.9079 4.75616 15.9353C3.81918 16.269 2.88221 16.6163 1.94523 16.95C1.46989 17.1191 1.00368 16.8357 0.957976 16.3421C0.944264 16.2141 0.980829 16.0679 1.02654 15.9445C1.35105 15.0349 1.6847 14.1254 2.01836 13.2158C2.05492 13.1107 2.0595 13.033 2.00008 12.9324C-0.536617 8.45321 1.71213 2.81307 6.63012 1.29562C11.1459 -0.098416 15.9405 2.75365 16.8455 7.38369C16.9506 7.90931 16.9871 8.44407 17.0511 8.97426Z" fill="#828282" />
                </svg></li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                  <path d="M11.1958 16.1871C10.9486 16.1157 10.6924 16.0692 10.4571 15.9637C9.3491 15.4672 8.74743 14.2725 8.97082 13.0251C8.98869 12.9258 8.96189 12.8885 8.8904 12.8451C7.41304 11.97 5.93568 11.0949 4.45832 10.2136C4.36598 10.1578 4.30939 10.164 4.22897 10.2385C3.45455 10.946 2.35249 11.0887 1.43509 10.6108C0.511742 10.1299 -0.0601395 9.11205 0.0321955 8.04768C0.12453 6.98332 0.651734 6.20755 1.59891 5.80104C2.54609 5.39454 3.43668 5.56211 4.22897 6.24479C4.2945 6.30064 4.33918 6.34409 4.43151 6.28823C5.94462 5.38523 7.4607 4.48533 8.96486 3.59164C8.94997 3.32477 8.92019 3.07032 8.92912 2.81276C8.96486 1.57773 9.90609 0.497853 11.0796 0.330286C11.1184 0.32408 11.1541 0.311668 11.1928 0.299255C11.3715 0.299255 11.5502 0.299255 11.729 0.299255C11.7558 0.308565 11.7855 0.320977 11.8124 0.327183C12.8995 0.491647 13.7752 1.36051 13.9599 2.45901C14.1535 3.61026 13.6352 4.73358 12.6583 5.28283C11.6873 5.82587 10.4988 5.65209 9.69759 4.8515C9.66184 4.81736 9.6261 4.78323 9.58738 4.74599C8.05343 5.6583 6.52543 6.5644 4.99446 7.47361C5.14041 7.99493 5.13445 8.50694 4.99744 9.01585C6.53437 9.92816 8.06236 10.8343 9.61419 11.7559C9.64993 11.7062 9.67376 11.6597 9.7095 11.6256C10.3112 11.0453 11.0201 10.8032 11.8302 10.9212C12.9919 11.0887 13.9301 12.1345 13.9927 13.3167C14.0641 14.648 13.3016 15.7744 12.1043 16.1002C11.9792 16.1344 11.8541 16.1592 11.729 16.1902C11.5532 16.1871 11.3745 16.1871 11.1958 16.1871Z" fill="#828282" />
                </svg></li>
              </ul>
            </th>
            <th><h3>Action</h3></th>
          </tr>
        </thead>
        <tbody>
        {rows.map((row) => (
          <tr>
            <td><input type="checkbox" name="" id="" /></td>
            <td><h2>Tempor purus sed pellentesque integer non</h2></td>
            <td><h4>Brooklyn Simmons</h4></td>
            <td><button className="pagesdash_published" >Published</button></td>
            <td><h5>June 23, 2022 at 2:01 pm</h5></td>
            <td>
              <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <circle cx="8" cy="8" r="8" fill="#89C400" />
                </svg></li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <circle cx="8" cy="8" r="8" fill="#F2C94C" />
                </svg></li>
                <li>5</li>
                <li>2</li>
              </ul>
            </td>
            <td><svg xmlns="http://www.w3.org/2000/svg" width="60" height="35" viewBox="0 0 60 35" fill="none">
              <rect width="60" height="35" rx="10" fill="#F2F2F2" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M51.3105 15.8014C51.701 16.192 51.701 16.8251 51.3105 17.2157L47.2161 21.31C46.8256 21.7005 46.1924 21.7005 45.8019 21.31L41.7076 17.2157C41.3171 16.8251 41.3171 16.192 41.7076 15.8014C42.0981 15.4109 42.7313 15.4109 43.1218 15.8014L46.509 19.1887L49.8963 15.8014C50.2868 15.4109 50.9199 15.4109 51.3105 15.8014Z" fill="#828282" />
              <path d="M16.6835 7C17.1416 7.03068 17.4898 7.22707 17.7769 7.57074C18.3816 8.29489 19.1635 8.66311 20.1103 8.63243C20.9838 8.60174 21.6924 8.22739 22.2482 7.55232C22.5536 7.18411 22.9323 7 23.4088 7.03068C23.5432 7.03682 23.6837 7.07364 23.8058 7.1166C24.9787 7.54619 26.0415 8.16602 27.0005 8.96996C27.4892 9.38113 27.6175 9.92118 27.3976 10.5656C26.8234 12.2287 27.7641 13.9593 29.4683 14.3582C29.566 14.3827 29.6638 14.395 29.7615 14.4196C30.3235 14.5423 30.7144 14.9535 30.8244 15.5181C31.0626 16.7823 31.0565 18.0404 30.8182 19.3046C30.7083 19.906 30.2624 20.3417 29.6516 20.4399C27.7152 20.7529 26.6829 22.5326 27.3609 24.3798C27.5869 24.9935 27.4342 25.5643 26.9272 25.9877C26.0782 26.6873 25.1497 27.2458 24.1296 27.6692C23.4699 27.9392 22.8896 27.792 22.4376 27.2335C21.2892 25.8036 19.249 25.7115 18.0457 27.0371C17.9479 27.1414 17.8624 27.2642 17.7769 27.3746C17.3982 27.8533 16.8118 28.0313 16.2376 27.8165C15.0464 27.3808 13.9775 26.761 13.0124 25.9509C12.542 25.552 12.4199 24.9506 12.6764 24.343C13.0246 23.5084 13.0185 22.686 12.6092 21.8821C12.1633 21.0045 11.4364 20.489 10.4652 20.3417C9.64055 20.219 9.24961 19.8508 9.12134 19.0284C8.93198 17.8194 8.96863 16.6166 9.20074 15.4199C9.32902 14.7755 9.77493 14.3766 10.4469 14.2907C11.9739 14.0882 13.0429 12.8485 12.9757 11.3143C12.9635 11.0074 12.878 10.6944 12.7864 10.3999C12.5665 9.73708 12.7192 9.17248 13.2689 8.74289C14.1546 8.05555 15.1198 7.50937 16.1765 7.12274C16.3353 7.06137 16.5125 7.04296 16.6835 7ZM20.0064 21.1334C22.0283 21.1334 23.6654 19.4825 23.6654 17.4389C23.6654 15.4322 22.0161 13.7813 20.0187 13.7813C17.9907 13.7813 16.3414 15.4137 16.3353 17.4267C16.3292 19.4764 17.9662 21.1272 20.0064 21.1334Z" fill="#828282" />
            </svg></td>
          </tr>
             ))}
        </tbody>
      </table>
    </div>
  )
}

export default PagesDashboardTable