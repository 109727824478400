import React from "react";


import Awards  from '../img/Award2022.png'



const Award= ()=>{


return(



    <>

<div class="About-main-card-1-ah">
                        <h2>Award</h2>

                        <div class="Award-box-ah">

                          

                            <div class="Award-card-ah">
                                <div class="Award-box-logo-ah">
                                <img src={Awards} alt=""/>
                                </div>

                                <div class="Award-box-tital-ah">
                                    <h2>Award 2022</h2>
                                    <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.    </p>
                                </div>
                            </div>


                            <div class="Award-card-ah">
                                <div class="Award-box-logo-ah">
                                   <img src={Awards} alt=""/>
                                </div>

                                <div class="Award-box-tital-ah">
                                    <h2>Award 2021</h2>
                                    <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.    </p>
                                </div>
                            </div>


                            <div class="Award-card-ah">
                                <div class="Award-box-logo-ah">
                                   <img src={Awards} alt=""/>
                                </div>

                                <div class="Award-box-tital-ah">
                                    <h2>Award 2023</h2>
                                    <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.    </p>
                                </div>
                            </div>



                        </div>

                        <div class="Award-a">
                            <a href="#">3+ more</a>
                        </div>
                    </div>

    </>
    
)




}



export default Award
