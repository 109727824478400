import React from "react";

import kabc from '../img/kabc.png'

import Education from '../img/Education-logo.png'


const Educationah = ()=>{


return(



    <>

<div class="About-main-card-1-ah">
                        <h2>Education</h2>

                        <div class="Education-ah-boxing">
                            
                            <div class="Education-card-ah">
                                <div class="Education-logo-side">
                                    <div class="Education-logo">
                                        <img src={Education} alt=""/>
                                    </div>
                                    <div class="Education-side-ah">
                                        <h2>Magister of Loream ipsum</h2>
                                        <h3>University of Illinois</h3>
                                        <p>Oct 2021  -  Apr 2022</p>
                                        <h4>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.</h4>
                                    </div>
                                </div>

                            </div>

                            <div class="Education-card-ah">
                                <div class="Education-logo-side">
                                    <div class="Education-logo">
                                    <img src={Education} alt=""/>
                                    </div>
                                    <div class="Education-side-ah">
                                        <h2>Bachelor of Loream ipsum</h2>
                                        <h3>University of Illinois</h3>
                                        <p>Oct 2021  -  Apr 2022</p>
                                        <h4>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna.</h4>
                                    </div>
                                </div>

                            
                            </div>

                        </div>
</div>


    </>
    
)


}



export default Educationah