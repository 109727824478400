import React from "react";

import kabc from '../img/kabc.png'


const About1 = ()=>{


return(



    <>

        

<div class="About-main-card-1-ah">
                      <span class="edite">
                        <h2>About</h2>
                       
                    </span>
                        <p>Pellentesque vel tristique ultrices habitasse feugiat nulla tempor quam. Et risus commodo natoque pulvinar eu, interdum. Viverra tortor hac sollicitudin dictum sit. Condimentum eget et commodo sapien porta felis amet pellentesque. Erat augue enim turpis risus urna, ut egestas vivamus proin. Velit leo scelerisque pulvinar vestibulum in nunc a tortor mollis. Fusce pretium est ipsum elementum parturient. Venenatis, ac nulla non varius dolor. Ornare consectetur mauris, adipiscing tristique a leo. Habitant pharetra tellus habitasse vestibulum nibh quisque. Sed vel condimentum enim leo tortor mi magna tincidunt. Egestas bibendum id eget quisque semper ultricies. Gravida viverra massa faucibus vestibulum condimentum.</p>
                        <hr/>
    
                        <div class="Industry-ah">
                            <span>
                                <h2>Industry</h2>
                                <p>TV Broadcasting</p>
                            </span>
    
                            <span>
                                <h2>Station Address</h2>
                                <p>3891 Ranchview Dr. Richardson, California 62639</p>
                            </span>
                        </div>
    
                        </div>
    </>
    
)




}



export default About1